import * as Sentry from '@sentry/browser';

Sentry.init({
  ...window.sentryConfig,
  ignoreErrors: [
    "Non-Error promise rejection captured with value" // https://sentry.io/organizations/church-initiative/issues/2344813400/?project=1528232
  ]
});

Sentry.configureScope((scope) => {
  scope.setTag("logger", "javascript");
});

window.Sentry = Sentry;
